<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic Info" :item="item" hide-edit>
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Host URL </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(hostBookingURL)">
              {{ hostBookingURL }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="![BOOKING_SOURCE.MANUAL, BOOKING_SOURCE.CALENDAR_IMPORT].includes(item.source)">
            <v-list-item-title> Artist URL </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(artistBookingURL)">
              {{ artistBookingURL }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Booking Number </v-list-item-title>
            <v-list-item-subtitle> {{ item.bookingNumber }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.name">
            <v-list-item-title> Enquiry Title </v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.venueType">
            <v-list-item-title> Venue Type </v-list-item-title>
            <v-list-item-subtitle> {{ item.venueType }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.multiple">
            <v-list-item-title> Book multiple days </v-list-item-title>
            <v-list-item-subtitle> {{ item.multiple ? 'Yes' : 'No' }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.isDateTimeFlexible">
            <v-list-item-title> Flexible on dates or times </v-list-item-title>
            <v-list-item-subtitle> {{ item.isDateTimeFlexible ? 'Yes' : 'No' }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.paymentStatus">
            <v-list-item-title> Payment Status</v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ item.paymentStatus }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.cost">
            <v-list-item-title> Cost</v-list-item-title>
            <v-list-item-subtitle> {{ item.cost | price }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.type">
            <v-list-item-title>Event Type</v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ item.type }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.manualSource">
            <v-list-item-title>Enquiry Source</v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ item.manualSource }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.enquiryTypeId">
            <v-list-item-title>Enquiry Type</v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ item.enquiryTypeId.name }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.contactId">
            <v-list-item-title> Contact</v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ item.contactId.firstName + ' ' + item.contactId.lastName }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Auto block events</v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ !item.excludeSlots ? 'Yes' : 'No' }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.description">
            <v-list-item-title> Notes</v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ item.description }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Date </v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              <BookingDateTime :booking="item" />
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item?.venueData" :to="$toViewVenue(item?.venueData, item.venueType)">
            <v-list-item-title> Listing Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.venueData?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('account', item.hostAccount?._id)">
            <v-list-item-title> Host profile </v-list-item-title>
            <v-list-item-subtitle> {{ item.hostAccount?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('account', item.artistAccount?._id)">
            <v-list-item-title> Artist profile </v-list-item-title>
            <v-list-item-subtitle> {{ item.artistAccount?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Artist ID </v-list-item-title>
            <v-list-item-subtitle @click="$copy(item.artistAccount?._id)">
              {{ item.artistAccount?._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.type !== EVENT_TYPE.BLOCKED">
            <v-list-item-title> Status </v-list-item-title>
            <v-list-item-subtitle>
              <BookingStatus class="text-lg py-4" admin :booking="item" />
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Description </v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              <BookingDescription :booking="item" admin />
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.stripe?.intentId">
            <v-list-item-title> Stripe Intent url </v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              <vx-link blank :href="`https://dashboard.stripe.com/payments/${item.stripe?.intentId}`">
                {{ `https://dashboard.stripe.com/payments/${item.stripe?.intentId}` }}
              </vx-link>
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.cancellationReason">
            <v-list-item-title> Cancellation Reason </v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ item.cancellationReason }}
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12" sm="6">
        <vx-card-list v-if="item.journey" title="Charges" :item="item" hide-edit>
          <v-list-item>
            <v-list-item-title> Cost/Quote </v-list-item-title>
            <v-list-item-subtitle> {{ item.cost | price }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Artist (will pay/paid) </v-list-item-title>
            <v-list-item-subtitle>
              {{ (item.discount && item.discount.totalAmount ? item.discount.totalAmount : item.cost) | price }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.discount?.totalDiscountAmount">
            <v-list-item-title> Discount applied </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.discount.totalDiscountAmount | price }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Host (will receive/received) </v-list-item-title>
            <v-list-item-subtitle> {{ item.hostPayout | price }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Tutti commission </v-list-item-title>
            <v-list-item-subtitle> {{ item.commission | price }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.journey" title="References" :item="item" hide-edit>
          <v-list-item :to="$toList('payment', { bookingId: this.$route.params.id })">
            <v-list-item-title> External payment </v-list-item-title>
            <v-list-item-subtitle> {{ item.payments }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.journey" title="Transaction Information" :item="item" hide-edit>
          <v-list-item>
            <v-list-item-title> Created date </v-list-item-title>
            <v-list-item-subtitle> {{ item.createdAt | date }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Request sent </v-list-item-title>
            <v-list-item-subtitle> {{ item.journey.requestSentAt | messageDateTime }} </v-list-item-subtitle>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-title> Request accepted </v-list-item-title>
            <v-list-item-subtitle> {{ item.journey.requestAcceptedAt | messageDateTime }} </v-list-item-subtitle>
          </v-list-item> -->

          <v-list-item>
            <v-list-item-title> Host edited </v-list-item-title>
            <v-list-item-subtitle> {{ item.journey.lastCostEditedAt | messageDateTime }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Host accepted </v-list-item-title>
            <v-list-item-subtitle> {{ item.journey.hostAcceptedAt | messageDateTime }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Artist paid </v-list-item-title>
            <v-list-item-subtitle> {{ item.journey.artistPaidAt | messageDateTime }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Payout Date </v-list-item-title>
            <v-list-item-subtitle> {{ item.journey.payoutAt | date }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Artist completed </v-list-item-title>
            <v-list-item-subtitle> {{ item.journey.completedAt | messageDateTime }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              {{ `Request ${item.status == 'cancelled' ? 'cancelled' : 'rejected'}:` }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.journey.requestCancelledAt | messageDateTime }}
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.journey && item.payouts" title="Stripe events" :item="item" hide-edit>
          <v-list-item>
            <v-list-item-title> Artist payment received </v-list-item-title>
            <v-list-item-subtitle> {{ item.journey.artistPaidAt | date }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Available to pay out </v-list-item-title>
            <v-list-item-subtitle> {{ item.endDate | date }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Paid host </v-list-item-title>
            <v-list-item-subtitle> {{ item.payouts.paidToHost | date }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <message-view
          :key="reviews.length"
          :booking="item"
          :filter="{
            bookingId: $route.params.id,
          }"
          :reviews="reviews"
          :change-title="false"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MessageView from '@admin/views/message/view/base';
import { BookingService, ReviewService } from '@tutti/services';
import { BookingStatus, BookingDescription, BookingDateTime } from '@tutti/components';
import { BOOKING_SOURCE, EVENT_TYPE } from '@tutti/constants';

export default {
  components: {
    MessageView,
    BookingStatus,
    BookingDateTime,
    BookingDescription,
  },
  data() {
    return {
      item: null,
      count: {},
      reviews: [],
      BOOKING_SOURCE,
      EVENT_TYPE,
      hostBookingURL: null,
      artistBookingURL: null,
    };
  },
  async created() {
    await this.getBooking();
    await this.getReviews();
  },
  methods: {
    async getBooking() {
      const response = await BookingService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.bookingNumber);

        if ([BOOKING_SOURCE.MANUAL, BOOKING_SOURCE.CALENDAR_IMPORT].includes(this.item.source)) {
          this.hostBookingURL = `${this.hostURL}/${this.item.hostAccount.slug}/a/event/overview/${this.item.bookingNumber}`;
          this.artistBookingURL = null;
        } else {
          this.hostBookingURL = `${this.artistURL}/${this.item.hostAccount.slug}/a/inbox/booking/${this.item.bookingNumber}`;
          this.artistBookingURL = `${this.artistURL}/${this.item.artistAccount.slug}/a/inbox/booking/${this.item.bookingNumber}`;
        }
      }
    },
    async getReviews() {
      if (this.item._id) {
        const response = await ReviewService.get({
          bookingId: this.item._id,
        });

        if (response && response.data) {
          this.reviews = response.data.data || [];
        }
      }
    },
  },
};
</script>

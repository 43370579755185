var render = function () {
  var _vm$message$origin, _vm$message$origin2, _vm$message$origin2$s, _vm$message$origin3, _vm$message$origin4, _vm$message$origin4$a, _vm$message$origin5, _vm$message$origin6, _vm$message$origin6$b;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.message.sendBy === _vm.ROLE.ADMIN ? _c('div', [_vm._v("Sent by Gabriel from Tutti")]) : _vm.message.origin ? _c('div', [(_vm$message$origin = _vm.message.origin) !== null && _vm$message$origin !== void 0 && _vm$message$origin.spaceId ? [_vm._v(" Sent from "), _c('router-link', {
    staticClass: "dark--text",
    attrs: {
      "to": _vm.$toView('space', _vm.message.origin.spaceId._id)
    }
  }, [_vm._v(" " + _vm._s((_vm$message$origin2 = _vm.message.origin) === null || _vm$message$origin2 === void 0 ? void 0 : (_vm$message$origin2$s = _vm$message$origin2.spaceId) === null || _vm$message$origin2$s === void 0 ? void 0 : _vm$message$origin2$s.name) + " ")]), _vm._v(" in " + _vm._s(_vm.message.origin.spaceId.location.name) + " ")] : _vm._e(), (_vm$message$origin3 = _vm.message.origin) !== null && _vm$message$origin3 !== void 0 && _vm$message$origin3.accountId ? [_vm._v(" Sent from "), _c('router-link', {
    staticClass: "dark--text",
    attrs: {
      "to": _vm.$toView('account', _vm.message.origin.accountId._id)
    }
  }, [_vm._v(" " + _vm._s((_vm$message$origin4 = _vm.message.origin) === null || _vm$message$origin4 === void 0 ? void 0 : (_vm$message$origin4$a = _vm$message$origin4.accountId) === null || _vm$message$origin4$a === void 0 ? void 0 : _vm$message$origin4$a.name) + " ")]), _vm._v(" profile ")] : _vm._e(), (_vm$message$origin5 = _vm.message.origin) !== null && _vm$message$origin5 !== void 0 && _vm$message$origin5.bookingId ? [_vm._v(" Sent from "), _c('router-link', {
    staticClass: "dark--text",
    attrs: {
      "to": _vm.$toView('booking', _vm.message.origin.bookingId._id)
    }
  }, [_vm._v(" " + _vm._s((_vm$message$origin6 = _vm.message.origin) === null || _vm$message$origin6 === void 0 ? void 0 : (_vm$message$origin6$b = _vm$message$origin6.bookingId) === null || _vm$message$origin6$b === void 0 ? void 0 : _vm$message$origin6$b.bookingNumber) + " ")])] : _vm._e()], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm$item, _vm$item2, _vm$item$venueData, _vm$item$hostAccount, _vm$item$hostAccount2, _vm$item$artistAccoun, _vm$item$artistAccoun2, _vm$item$artistAccoun4, _vm$item$stripe, _vm$item$stripe2, _vm$item$stripe3, _vm$item$discount;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic Info",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Host URL ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.hostBookingURL);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.hostBookingURL) + " ")])], 1), ![_vm.BOOKING_SOURCE.MANUAL, _vm.BOOKING_SOURCE.CALENDAR_IMPORT].includes(_vm.item.source) ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Artist URL ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.artistBookingURL);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.artistBookingURL) + " ")])], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Booking Number ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.bookingNumber) + " ")])], 1), _vm.item.name ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Enquiry Title ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1) : _vm._e(), _vm.item.venueType ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Venue Type ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.venueType) + " ")])], 1) : _vm._e(), _vm.item.multiple ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Book multiple days ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.multiple ? 'Yes' : 'No') + " ")])], 1) : _vm._e(), _vm.item.isDateTimeFlexible ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Flexible on dates or times ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.isDateTimeFlexible ? 'Yes' : 'No') + " ")])], 1) : _vm._e(), _vm.item.paymentStatus ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Payment Status")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s(_vm.item.paymentStatus) + " ")])], 1) : _vm._e(), _vm.item.cost ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Cost")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.item.cost)) + " ")])], 1) : _vm._e(), _vm.item.type ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Event Type")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s(_vm.item.type) + " ")])], 1) : _vm._e(), _vm.item.manualSource ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Enquiry Source")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s(_vm.item.manualSource) + " ")])], 1) : _vm._e(), _vm.item.enquiryTypeId ? _c('v-list-item', [_c('v-list-item-title', [_vm._v("Enquiry Type")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s(_vm.item.enquiryTypeId.name) + " ")])], 1) : _vm._e(), _vm.item.contactId ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Contact")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s(_vm.item.contactId.firstName + ' ' + _vm.item.contactId.lastName) + " ")])], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Auto block events")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s(!_vm.item.excludeSlots ? 'Yes' : 'No') + " ")])], 1), _vm.item.description ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Notes")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s(_vm.item.description) + " ")])], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Date ")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_c('BookingDateTime', {
    attrs: {
      "booking": _vm.item
    }
  })], 1)], 1), (_vm$item = _vm.item) !== null && _vm$item !== void 0 && _vm$item.venueData ? _c('v-list-item', {
    attrs: {
      "to": _vm.$toViewVenue((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.venueData, _vm.item.venueType)
    }
  }, [_c('v-list-item-title', [_vm._v(" Listing Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$venueData = _vm.item.venueData) === null || _vm$item$venueData === void 0 ? void 0 : _vm$item$venueData.name) + " ")])], 1) : _vm._e(), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('account', (_vm$item$hostAccount = _vm.item.hostAccount) === null || _vm$item$hostAccount === void 0 ? void 0 : _vm$item$hostAccount._id)
    }
  }, [_c('v-list-item-title', [_vm._v(" Host profile ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$hostAccount2 = _vm.item.hostAccount) === null || _vm$item$hostAccount2 === void 0 ? void 0 : _vm$item$hostAccount2.name) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('account', (_vm$item$artistAccoun = _vm.item.artistAccount) === null || _vm$item$artistAccoun === void 0 ? void 0 : _vm$item$artistAccoun._id)
    }
  }, [_c('v-list-item-title', [_vm._v(" Artist profile ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$artistAccoun2 = _vm.item.artistAccount) === null || _vm$item$artistAccoun2 === void 0 ? void 0 : _vm$item$artistAccoun2.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Artist ID ")]), _c('v-list-item-subtitle', {
    on: {
      "click": function click($event) {
        var _vm$item$artistAccoun3;

        return _vm.$copy((_vm$item$artistAccoun3 = _vm.item.artistAccount) === null || _vm$item$artistAccoun3 === void 0 ? void 0 : _vm$item$artistAccoun3._id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$item$artistAccoun4 = _vm.item.artistAccount) === null || _vm$item$artistAccoun4 === void 0 ? void 0 : _vm$item$artistAccoun4._id) + " ")])], 1), _vm.item.type !== _vm.EVENT_TYPE.BLOCKED ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Status ")]), _c('v-list-item-subtitle', [_c('BookingStatus', {
    staticClass: "text-lg py-4",
    attrs: {
      "admin": "",
      "booking": _vm.item
    }
  })], 1)], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Description ")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_c('BookingDescription', {
    attrs: {
      "booking": _vm.item,
      "admin": ""
    }
  })], 1)], 1), (_vm$item$stripe = _vm.item.stripe) !== null && _vm$item$stripe !== void 0 && _vm$item$stripe.intentId ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Stripe Intent url ")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_c('vx-link', {
    attrs: {
      "blank": "",
      "href": "https://dashboard.stripe.com/payments/".concat((_vm$item$stripe2 = _vm.item.stripe) === null || _vm$item$stripe2 === void 0 ? void 0 : _vm$item$stripe2.intentId)
    }
  }, [_vm._v(" " + _vm._s("https://dashboard.stripe.com/payments/".concat((_vm$item$stripe3 = _vm.item.stripe) === null || _vm$item$stripe3 === void 0 ? void 0 : _vm$item$stripe3.intentId)) + " ")])], 1)], 1) : _vm._e(), _vm.item.cancellationReason ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Cancellation Reason ")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s(_vm.item.cancellationReason) + " ")])], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.item.journey ? _c('vx-card-list', {
    attrs: {
      "title": "Charges",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Cost/Quote ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.item.cost)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Artist (will pay/paid) ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.item.discount && _vm.item.discount.totalAmount ? _vm.item.discount.totalAmount : _vm.item.cost)) + " ")])], 1), (_vm$item$discount = _vm.item.discount) !== null && _vm$item$discount !== void 0 && _vm$item$discount.totalDiscountAmount ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Discount applied ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.item.discount.totalDiscountAmount)) + " ")])], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Host (will receive/received) ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.item.hostPayout)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Tutti commission ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.item.commission)) + " ")])], 1)], 1) : _vm._e(), _vm.item.journey ? _c('vx-card-list', {
    attrs: {
      "title": "References",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": _vm.$toList('payment', {
        bookingId: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" External payment ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.payments) + " ")])], 1)], 1) : _vm._e(), _vm.item.journey ? _c('vx-card-list', {
    attrs: {
      "title": "Transaction Information",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Created date ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("date")(_vm.item.createdAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Request sent ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("messageDateTime")(_vm.item.journey.requestSentAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Host edited ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("messageDateTime")(_vm.item.journey.lastCostEditedAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Host accepted ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("messageDateTime")(_vm.item.journey.hostAcceptedAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Artist paid ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("messageDateTime")(_vm.item.journey.artistPaidAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Payout Date ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("date")(_vm.item.journey.payoutAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Artist completed ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("messageDateTime")(_vm.item.journey.completedAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" " + _vm._s("Request ".concat(_vm.item.status == 'cancelled' ? 'cancelled' : 'rejected', ":")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("messageDateTime")(_vm.item.journey.requestCancelledAt)) + " ")])], 1)], 1) : _vm._e(), _vm.item.journey && _vm.item.payouts ? _c('vx-card-list', {
    attrs: {
      "title": "Stripe events",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Artist payment received ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("date")(_vm.item.journey.artistPaidAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Available to pay out ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("date")(_vm.item.endDate)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Paid host ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("date")(_vm.item.payouts.paidToHost)) + " ")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('message-view', {
    key: _vm.reviews.length,
    attrs: {
      "booking": _vm.item,
      "filter": {
        bookingId: _vm.$route.params.id
      },
      "reviews": _vm.reviews,
      "change-title": false
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="message.sendBy === ROLE.ADMIN">Sent by Gabriel from Tutti</div>
  <div v-else-if="message.origin">
    <template v-if="message.origin?.spaceId">
      Sent from
      <router-link class="dark--text" :to="$toView('space', message.origin.spaceId._id)">
        {{ message.origin?.spaceId?.name }}
      </router-link>
      in
      {{ message.origin.spaceId.location.name }}
    </template>

    <template v-if="message.origin?.accountId">
      Sent from
      <router-link class="dark--text" :to="$toView('account', message.origin.accountId._id)">
        {{ message.origin?.accountId?.name }}
      </router-link>
      profile
    </template>

    <template v-if="message.origin?.bookingId">
      Sent from
      <router-link class="dark--text" :to="$toView('booking', message.origin.bookingId._id)">
        {{ message.origin?.bookingId?.bookingNumber }}
      </router-link>
    </template>
  </div>
</template>

<script>
import { ROLE } from '@tutti/constants';

export default {
  name: 'MessageOrigin',

  props: {
    message: { type: Object, required: true },
  },

  data() {
    return {
      ROLE,
    };
  },
};
</script>

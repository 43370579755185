<template>
  <div v-if="created">
    <v-card outlined>
      <v-card-text>
        <vx-input v-model="message.message" type="textarea" />

        <vx-btn class="d-flex mt-4 ml-auto" large color="primary" @click="sendMessage()">Send message</vx-btn>
      </v-card-text>

      <v-divider />

      <v-card-text v-if="booking?.questions?.length" class="px-0">
        <v-list>
          <template v-for="(question, index) in booking.questions">
            <v-list-item v-if="question.answer" :key="index" class="mb-4">
              <v-list-item-content class="dark lighten-1 px-2 rounded">
                <v-list-item-title class="font-bold mb-2 white-space-normal">
                  {{ question.questionId.name }}
                </v-list-item-title>

                <v-list-item-title>
                  <div class="white-space-pre-line" v-html="question.answer"></div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <v-divider />
      </v-card-text>

      <v-card-text>
        <template v-for="(review, index) in reviews">
          <v-row :key="'review' + index">
            <v-col cols="12">
              <v-list class="pa-0" rounded color="warning lighten-5">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-bold text-subtitle-2 py-2">
                      {{ review.from?.name }}
                    </v-list-item-title>

                    <v-list-item-title>
                      <v-rating readonly :value="review.rating"></v-rating>
                    </v-list-item-title>

                    <v-list-item-title class="white-space-normal text-subtitle-2" v-html="review.review">
                    </v-list-item-title>

                    <v-list-item-subtitle class="text-caption font-bold">
                      {{ review.createdAt | messageDateTime }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </template>

        <template v-for="(message, index) in messages">
          <v-row
            v-if="lastMessage.from"
            :key="index"
            :justify="lastMessage.from._id !== message.from._id ? 'end' : 'start'"
          >
            <v-col cols="9">
              <v-list
                class="pa-0"
                rounded
                :color="
                  message.sendBy === 'admin'
                    ? 'dark lighten-4'
                    : lastMessage.from._id == message.from._id
                    ? 'success lighten-2'
                    : 'info lighten-2'
                "
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-bold text-subtitle-2 py-2">
                      <router-link class="black--text" :to="$toView('account', message.from._id)">
                        {{ message.from?.name }}
                      </router-link>

                      <router-link
                        v-if="message.createdBy"
                        class="black--text"
                        :to="$toView('user', message.createdBy._id)"
                      >
                        (@{{ message.createdBy?.username }})
                      </router-link>
                    </v-list-item-title>

                    <v-list-item-title class="white-space-pre-line text-subtitle-2 py-2" v-html="message.message">
                    </v-list-item-title>

                    <v-list-item-subtitle class="text-caption black--text font-bold">
                      {{ message.createdAt | messageDateTime }}

                      <MessageOrigin class="float-right" :message="message" />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { MessageService, BookingService } from '@tutti/services';
import { Message } from '@tutti/models';
import _ from 'lodash';
import MessageOrigin from './origin.vue';
import { BOOKING_SOURCE } from '@tutti/constants';

export default {
  name: 'MessageBase',

  components: { MessageOrigin },

  props: {
    filter: { type: Object, default: () => ({}) },
    reviews: { type: Array, default: () => [] },
    changeTitle: { type: Boolean, default: true },
  },

  data() {
    return {
      created: false,
      message: new Message(),
      booking: null,
      BOOKING_SOURCE,
      messages: [],
      lastMessage: null,
    };
  },

  async created() {
    await this.getMessages();

    this.created = true;
  },

  methods: {
    getColor(message) {
      if (message.sendBy === 'admin') {
        return 'dark';
      } else if (message.sendBy === 'host') {
        return 'success lighten-1';
      } else if (message.sendBy === 'user') {
        return 'info lighten-1';
      } else {
        return 'primary lighten-1 ';
      }
    },

    async getMessages() {
      this.filter.sortBy = 'desc';
      const response = await MessageService.getMessages(this.filter);
      if (response?.data?.length) {
        this.messages = response.data;
        this.lastMessage = _.last(this.messages);

        const displayData = _.cloneDeep(this.lastMessage);
        this.$emit('getMessage', displayData);

        const booking = await BookingService.getByIdAdmin(this.lastMessage.bookingId);
        if (booking?.data) {
          this.booking = booking.data;
          await this.message.setBooking(this.booking);
        }
        if (this.booking && this.booking.source !== BOOKING_SOURCE.TUTTI_MARKETPLACE) {
          this.lastMessage.to = {};
        }

        this.message.setParentId(this.lastMessage, true);

        const [message] = this.messages.concat(this.messages);

        const title = `Message b/w ${message.from.name} and ${message.to?.name}`;

        if (this.changeTitle) {
          this.$setTitle(title);
        }
      }
    },

    async sendMessage() {
      this.loading = true;

      if (this.message.message) {
        let response = null;
        if (
          this.lastMessage.messageType === 'Direct message' ||
          this.booking.source === BOOKING_SOURCE.TUTTI_MARKETPLACE
        ) {
          response = await MessageService.sendFromAdmin(this.message.get());
        } else {
          response = await MessageService.sendNotesFromAdmin(this.message.getNote());
        }
        if (response) {
          this.message.message = '';
          this.getMessages();
        }
      }

      this.loading = false;
    },
  },
};
</script>

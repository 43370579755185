var render = function () {
  var _vm$booking, _vm$booking$questions;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.created ? _c('div', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('vx-input', {
    attrs: {
      "type": "textarea"
    },
    model: {
      value: _vm.message.message,
      callback: function callback($$v) {
        _vm.$set(_vm.message, "message", $$v);
      },
      expression: "message.message"
    }
  }), _c('vx-btn', {
    staticClass: "d-flex mt-4 ml-auto",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.sendMessage();
      }
    }
  }, [_vm._v("Send message")])], 1), _c('v-divider'), (_vm$booking = _vm.booking) !== null && _vm$booking !== void 0 && (_vm$booking$questions = _vm$booking.questions) !== null && _vm$booking$questions !== void 0 && _vm$booking$questions.length ? _c('v-card-text', {
    staticClass: "px-0"
  }, [_c('v-list', [_vm._l(_vm.booking.questions, function (question, index) {
    return [question.answer ? _c('v-list-item', {
      key: index,
      staticClass: "mb-4"
    }, [_c('v-list-item-content', {
      staticClass: "dark lighten-1 px-2 rounded"
    }, [_c('v-list-item-title', {
      staticClass: "font-bold mb-2 white-space-normal"
    }, [_vm._v(" " + _vm._s(question.questionId.name) + " ")]), _c('v-list-item-title', [_c('div', {
      staticClass: "white-space-pre-line",
      domProps: {
        "innerHTML": _vm._s(question.answer)
      }
    })])], 1)], 1) : _vm._e()];
  })], 2), _c('v-divider')], 1) : _vm._e(), _c('v-card-text', [_vm._l(_vm.reviews, function (review, index) {
    var _review$from;

    return [_c('v-row', {
      key: 'review' + index
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-list', {
      staticClass: "pa-0",
      attrs: {
        "rounded": "",
        "color": "warning lighten-5"
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "font-bold text-subtitle-2 py-2"
    }, [_vm._v(" " + _vm._s((_review$from = review.from) === null || _review$from === void 0 ? void 0 : _review$from.name) + " ")]), _c('v-list-item-title', [_c('v-rating', {
      attrs: {
        "readonly": "",
        "value": review.rating
      }
    })], 1), _c('v-list-item-title', {
      staticClass: "white-space-normal text-subtitle-2",
      domProps: {
        "innerHTML": _vm._s(review.review)
      }
    }), _c('v-list-item-subtitle', {
      staticClass: "text-caption font-bold"
    }, [_vm._v(" " + _vm._s(_vm._f("messageDateTime")(review.createdAt)) + " ")])], 1)], 1)], 1)], 1)], 1)];
  }), _vm._l(_vm.messages, function (message, index) {
    var _message$from, _message$createdBy;

    return [_vm.lastMessage.from ? _c('v-row', {
      key: index,
      attrs: {
        "justify": _vm.lastMessage.from._id !== message.from._id ? 'end' : 'start'
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "9"
      }
    }, [_c('v-list', {
      staticClass: "pa-0",
      attrs: {
        "rounded": "",
        "color": message.sendBy === 'admin' ? 'dark lighten-4' : _vm.lastMessage.from._id == message.from._id ? 'success lighten-2' : 'info lighten-2'
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "font-bold text-subtitle-2 py-2"
    }, [_c('router-link', {
      staticClass: "black--text",
      attrs: {
        "to": _vm.$toView('account', message.from._id)
      }
    }, [_vm._v(" " + _vm._s((_message$from = message.from) === null || _message$from === void 0 ? void 0 : _message$from.name) + " ")]), message.createdBy ? _c('router-link', {
      staticClass: "black--text",
      attrs: {
        "to": _vm.$toView('user', message.createdBy._id)
      }
    }, [_vm._v(" (@" + _vm._s((_message$createdBy = message.createdBy) === null || _message$createdBy === void 0 ? void 0 : _message$createdBy.username) + ") ")]) : _vm._e()], 1), _c('v-list-item-title', {
      staticClass: "white-space-pre-line text-subtitle-2 py-2",
      domProps: {
        "innerHTML": _vm._s(message.message)
      }
    }), _c('v-list-item-subtitle', {
      staticClass: "text-caption black--text font-bold"
    }, [_vm._v(" " + _vm._s(_vm._f("messageDateTime")(message.createdAt)) + " "), _c('MessageOrigin', {
      staticClass: "float-right",
      attrs: {
        "message": message
      }
    })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()];
  })], 2)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }